/**
 * Enum of PGNs as defined by the server
 */
export const PGN = Object.freeze({
  LXM_IOT_ENGINE_DATA_1: 0x0,
  LXM_IOT_ENGINE_DATA_2: 0x1,
  LXM_IOT_TEMPERATURE: 0x2,
  LXM_IOT_DC_STATUS_1: 0x3,
  LXM_IOT_DC_STATUS_2: 0x4,
  LXM_IOT_GNSS_DATA_1: 0x5,
  LXM_IOT_GNSS_DATA_2: 0x6,
  LXM_IOT_WREF_SPD: 0x7,
  LXM_IOT_FLUID: 0x8,
  LXM_IOT_RUDDER: 0x9,
  LXM_IOT_WARNINGS_1: 0xA,
  LXM_IOT_WATER_DEPTH: 0xB,
  LXM_IOT_STAT_1: 0xC,
  LXM_IOT_STAT_2: 0xD
});

export const PGN_VALUE = {
  LXM_IOT_GNSS_DATA_1__LAT: 'Lat',
  LXM_IOT_GNSS_DATA_1__LON: 'Lon',
  LXM_IOT_GNSS_DATA_2__COG: 'COG',
  LXM_IOT_GNSS_DATA_2__SOG: 'SOG',
  LXM_IOT_ENGINE_DATA_1__RPM: 'Rpm',
  LXM_IOT_ENGINE_DATA_1__FUEL_RATE: 'FuelRate',
  LXM_IOT_ENGINE_DATA_2__TEMP: 'Temp',
  LXM_IOT_ENGINE_DATA_2__TRIM: 'Trim',
  LXM_IOT_DC_STATUS_1__SOC: 'SOC',
  LXM_IOT_DC_STATUS_1__SOH: 'SOH',
  LXM_IOT_DC_STATUS_1__TEMP: 'Temp',
  LXM_IOT_DC_STATUS_2__A: 'A',
  LXM_IOT_DC_STATUS_2__V: 'V',
  LXM_IOT_DC_STATUS_2__TRM: 'TRm',
  LXM_IOT_DC_STATUS_2__RNG: 'Range',
  LXM_IOT_FLUID__LVL: 'Lvl',
  LXM_IOT_TEMPERATURE__TEMP: 'Temp',
  LXM_IOT_WATER_DEPTH__DEPTH: 'Depth',
  LXM_IOT_WREF_SPD__SPD: 'Spd',
  LXM_IOT_RUDDER__POS: 'Pos'
};

export const FLUID_TYPE = {
  FUEL: 0x0,
  FRESH_WATER: 0x1,
  WASTE_WATER: 0x2,
  LIVE_WELL: 0x3,
  OIL: 0x4,
  BLACK_WATER: 0x5,
  GASOLINE: 0x6
};

export const TEMP_TYPE = {
  WATER: 0x0,
  OUTSIDE: 0x1,
  INSIDE: 0x2,
  ENGINE_ROOM: 0x3,
  MAIN_CABIN: 0x4,
  LIVE_WELL: 0x5,
  BAIT_WELL: 0x6,
  REFRIGERATION: 0x7,
  HEATING: 0x8,
  DEW_POINT: 0x9,
  WIND_CHILL_APPARENT: 0xA,
  WIND_CHILL_THEORETIC: 0xB,
  HEAT_INDEX: 0xC,
  FREEZER: 0xD,
  EXHAUST_GAS: 0xE
};

export const DC_TYPE = Object.freeze({
  BATTERY: 0x0,
  ALTERNATOR: 0x1,
  CONVERTOR: 0x2,
  SOLAR_CELL: 0x3,
  WIND_GENERATOR: 0x4
});

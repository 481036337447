import { WARNING_CATEGORY, WARNING_TYPE } from '../../../utils/consts';
import { PGN, PGN_VALUE } from '../../pgn/consts'
import { getPgnPropertyDefinition } from '../../pgn/definitions';
import ParameterizedWarningDefinition from './definitions/parameterized_warning_definition';
import WarningDefinition from './definitions/warning_definition';

/**
 * Array of all known warning types. All elements are instances of {@link WarningDefinion}.
 */
const warningDefinitions = [
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_RPM_SPEED,
    PGN.LXM_IOT_ENGINE_DATA_1,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_ENGINE_DATA_1,
      PGN_VALUE.LXM_IOT_ENGINE_DATA_1__RPM
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_FUEL_RATE,
    PGN.LXM_IOT_ENGINE_DATA_1,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_ENGINE_DATA_1,
      PGN_VALUE.LXM_IOT_ENGINE_DATA_1__FUEL_RATE
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_ENGINE_TEMP,
    PGN.LXM_IOT_ENGINE_DATA_2,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_ENGINE_DATA_2,
      PGN_VALUE.LXM_IOT_ENGINE_DATA_2__TEMP
    )
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_CHECK_ENGINE,
    WARNING_CATEGORY.BASIC,
    'Check engine warning'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_OVER_TEMPERATURE,
    WARNING_CATEGORY.BASIC,
    'Engine overheating'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_LOW_OIL_PRESSURE,
    WARNING_CATEGORY.BASIC,
    'Low engine oil pressure'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_LOW_OIL_LEVEL,
    WARNING_CATEGORY.BASIC,
    'Low engine oil level'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_LOW_FUEL_PRESSURE,
    WARNING_CATEGORY.BASIC,
    'Low engine fuel pressure'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_LOW_SYSTEM_VOLTAGE,
    WARNING_CATEGORY.BASIC,
    'Low engine system volage'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_LOW_COOLANT_LEVEL,
    WARNING_CATEGORY.BASIC,
    'Low engine coolant level'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_WATER_FLOW,
    WARNING_CATEGORY.BASIC,
    'Engine water flow'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_WATER_IN_FUEL,
    WARNING_CATEGORY.BASIC,
    'Water in engine fuel'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_CHARGE_INDICATOR,
    WARNING_CATEGORY.BASIC,
    'Engine charge indicator'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_PREHEAT_INDICATOR,
    WARNING_CATEGORY.BASIC,
    'Engine preheat indicator'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_HIGH_BOOST_PRESSURE,
    WARNING_CATEGORY.BASIC,
    'Engine high boost pressure'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_REV_LIMIT_EXCEEDED,
    WARNING_CATEGORY.BASIC,
    'Engine rev limit exceeded'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_EGR_SYSTEM,
    WARNING_CATEGORY.BASIC,
    'Engine EGR system'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_THROTTLE_POSITION_SENSOR,
    WARNING_CATEGORY.BASIC,
    'Engine throttle position'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_ENGINE_EMERGENCY_STOP,
    WARNING_CATEGORY.BASIC,
    'Emergency engine stop'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_WARNING_LEVEL_1,
    WARNING_CATEGORY.BASIC,
    'Engine danger warning'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_WARNING_LEVEL_2,
    WARNING_CATEGORY.BASIC,
    'Engine critical warning'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_POWER_REDUCTION,
    WARNING_CATEGORY.BASIC,
    'Engine power reduction'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_MAINTENANCE_NEEDED,
    WARNING_CATEGORY.BASIC,
    'Engine maintanance needed'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_ENGINE_COMM_ERROR,
    WARNING_CATEGORY.BASIC,
    'Engine communication error'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_SUB_OR_SECONDARY_THROTTLE,
    WARNING_CATEGORY.BASIC,
    'Engine sub or secondary throttle'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_NEUTRAL_START_PROTECT,
    WARNING_CATEGORY.BASIC,
    'Engine start protection'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_BASIC_ENGINE_SHUTTING_DOWN,
    WARNING_CATEGORY.BASIC,
    'Engine shutting down'
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_ENGINE_TRIM,
    PGN.LXM_IOT_ENGINE_DATA_2,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_ENGINE_DATA_2,
      PGN_VALUE.LXM_IOT_ENGINE_DATA_2__TRIM
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_TEMP,
    PGN.LXM_IOT_TEMPERATURE,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_TEMPERATURE,
      PGN_VALUE.LXM_IOT_TEMPERATURE__TEMP
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_SOC,
    PGN.LXM_IOT_DC_STATUS_1,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_DC_STATUS_1,
      PGN_VALUE.LXM_IOT_DC_STATUS_1__SOC
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_VOLTAGE,
    PGN.LXM_IOT_DC_STATUS_2,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_DC_STATUS_2,
      PGN_VALUE.LXM_IOT_DC_STATUS_2__V
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_CURRENT,
    PGN.LXM_IOT_DC_STATUS_2,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_DC_STATUS_2,
      PGN_VALUE.LXM_IOT_DC_STATUS_2__A
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_GNSS_SPEED,
    PGN.LXM_IOT_GNSS_DATA_2,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_GNSS_DATA_2,
      PGN_VALUE.LXM_IOT_GNSS_DATA_2__SOG
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_WREF_SPEED,
    PGN.LXM_IOT_WREF_SPD,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_WREF_SPD,
      PGN_VALUE.LXM_IOT_WREF_SPD__SPD
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_FLUID,
    PGN.LXM_IOT_FLUID,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_FLUID,
      PGN_VALUE.LXM_IOT_FLUID__LVL
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_DEPTH,
    PGN.LXM_IOT_WATER_DEPTH,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_WATER_DEPTH,
      PGN_VALUE.LXM_IOT_WATER_DEPTH__DEPTH
    )
  ),
  new ParameterizedWarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_PARAM_BATTERY_TEMP,
    PGN.LXM_IOT_DC_STATUS_1,
    getPgnPropertyDefinition(
      PGN.LXM_IOT_DC_STATUS_1,
      PGN_VALUE.LXM_IOT_DC_STATUS_1__TEMP
    )
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_SPECIAL_GEOFENCE,
    WARNING_CATEGORY.GEOFENCE,
    'Geofence warning'
  ),
  new WarningDefinition(
    WARNING_TYPE.LXM_IOT_WARN_SPECIAL_ANCHOR,
    WARNING_CATEGORY.ANCHOR,
    'Left anchor zone'
  )
];

// Populate the look up object to be used later on
let defintionLookup = {};
for (const def of warningDefinitions)
  defintionLookup[def.getType()] = def;

export const removeFuelWarn = () => {
  const wdIndex = warningDefinitions.findIndex(wd => wd instanceof ParameterizedWarningDefinition && wd.getPgnPropertyKey() === PGN_VALUE.LXM_IOT_ENGINE_DATA_1__FUEL_RATE);
  if (wdIndex > -1) {
    warningDefinitions.splice(wdIndex, 1);
  }

  defintionLookup = {};
  for (const def of warningDefinitions)
    defintionLookup[def.getType()] = def;
}

export const addFuelWarn = () => {
  const wdIndex = warningDefinitions.findIndex(wd => wd instanceof ParameterizedWarningDefinition && wd.getPgnPropertyKey() === PGN_VALUE.LXM_IOT_ENGINE_DATA_1__FUEL_RATE);
  if (wdIndex < 0) {
    warningDefinitions.push(
      new ParameterizedWarningDefinition(
      WARNING_TYPE.LXM_IOT_WARN_PARAM_FUEL_RATE,
      PGN.LXM_IOT_ENGINE_DATA_1,
      getPgnPropertyDefinition(
        PGN.LXM_IOT_ENGINE_DATA_1,
        PGN_VALUE.LXM_IOT_ENGINE_DATA_1__FUEL_RATE
      )
    ));
  }

  defintionLookup = {};
  for (const def of warningDefinitions)
    defintionLookup[def.getType()] = def;
}

/**
 * Accepts a warning type and returns its definition. If the type is unknown, it returns undefined.
 * @param {number} type one of {@link WARNING_TYPE}
 * @returns {WarningDefinition|undefined}
 */
export const getWarningDefinition = type => defintionLookup[type];

export default warningDefinitions;
import { WARNING_CATEGORY } from '../../../../utils/consts';
import { eUnitStyle } from '../../../../utils/units';
import WarningDefinition from './warning_definition';

/**
 * Spcialized class of warnings which are bound to a PGN property's value.
 */
class ParameterizedWarningDefinition extends WarningDefinition {
  constructor(type, pgn, pgnPropertyDefinition) {
    super(type, WARNING_CATEGORY.TWO_LEVEL_PARAMETERIZED, pgnPropertyDefinition.label);

    this._pgnNumber = pgn;
    this._pgnPropertyDefinition = pgnPropertyDefinition;

    this.getPgnPropertyDefinition = this.getPgnPropertyDefinition.bind(this);

    this.getPgnNumber = this.getPgnNumber.bind(this);
    this.getPgnPropertyKey = this.getPgnPropertyKey.bind(this);
    this.getMetaLabel = this.getMetaLabel.bind(this);

    this.toUserUnits = this.toUserUnits.bind(this);
    this.getUserUnit = this.getUserUnit.bind(this);
    this.fromUserUnits = this.fromUserUnits.bind(this);
    this.valueToStr = this.valueToStr.bind(this);
  }

  /**
   * Returns the property defintion that this warning is bound to
   */
  getPgnPropertyDefinition() {
    return this._pgnPropertyDefinition;
  }

  getPgnNumber() {
    return this._pgnNumber;
  }

  getPgnPropertyKey() {
    return this.getPgnPropertyDefinition().key;
  }

  getCategory() {
    return WARNING_CATEGORY.TWO_LEVEL_PARAMETERIZED;
  }

  getUserUnit() {
    return this._pgnPropertyDefinition.getUserUnit();
  }

  /**
   * Converts the given value to the user's preffered unit.
   */
  toUserUnits(value) {
    return this._pgnPropertyDefinition.convertToUserUnit(value);
  }

  fromUserUnits(value) {
    return this._pgnPropertyDefinition.convertFromUserUnit(value);
  }

  valueToStr(val, eUnit = eUnitStyle.EUS_Short) {
    return this._pgnPropertyDefinition.valueToStr(val, eUnit);
  }

  getMetaLabel(metaData, short = false) {
    return this._pgnPropertyDefinition.getLabel(metaData, short);
  }
};

export default ParameterizedWarningDefinition;
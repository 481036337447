import { addFuelStats, removeFuelStats } from "../../utils/vessel/vessel_statistics";
import { addFuelWarn, removeFuelWarn } from "../constructs/warnings/app_warning_definitions";
import { addFuel, removeFuel } from "./definitions";

export const remove = () => {
  removeFuel();
  removeFuelWarn();
  removeFuelStats();
};

export const add = () => {
  addFuel()
  addFuelWarn();
  addFuelStats();
};
import { rawTimeZones } from '@vvo/tzdb';
import { sprintf } from 'sprintf-js';

import { unixToDate } from '../../../utils/general';

export const TZ_NAME__UNKNOWN = 'UNKNOWN';

// Make a look-up object fot the raw time zones
let rawTzLookUpObj = {};
for (const rawTz of rawTimeZones)
  rawTzLookUpObj[rawTz.name] = rawTz;


const dtfCache = {};
/**
 * Creates a DateTimeFormat instance for the given time zone name
 * 
 * RETURNS: DateTimeFormat
 */
function makeDTF(tzName) {
  if (!dtfCache[tzName]) {
    dtfCache[tzName] = new Intl.DateTimeFormat('en-US', {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }

  return dtfCache[tzName];
}

const typeToPos = {
  year: 0,
  month: 1,
  day: 2,
  hour: 3,
  minute: 4,
  second: 5
};

/**
 * Gets the time components for the given DateTimeFormat and Date
 * 
 * RETURNS: number[]
 */
function partsOffset(dtf, date) {
  if (!!dtf.formatToParts) {
    const formatted = dtf.formatToParts(date);
    const filled = [];
    for (let i = 0; i < formatted.length; i++) {
      const { type, value } = formatted[i];

      if (type in typeToPos)
        filled[typeToPos[type]] = parseInt(value, 10);
    }

    return filled;
  } else {
    // User-agent does not support formatToParts
    const formatted = dtf.format(date).replace(/\u200E/g, '');
    const parsed = /(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)/.exec(formatted);

    const [, fMonth, fDay, fYear, fHour, fMinute, fSecond] = parsed;
    return [
      fYear,
      fMonth,
      fDay,
      fHour,
      fMinute,
      fSecond
    ];
  }
}

/**
 * Class that encapsulates the use of IANA time zones 
 */
class IanaTimeZone {
  constructor(tzObject) {

    if (!tzObject) {
      tzObject = {
        name: TZ_NAME__UNKNOWN,
        mainCities: ['UNKNOWN'],
        alternativeName: TZ_NAME__UNKNOWN
      };
    }

    this._rawTz = tzObject;
    this._dtf = makeDTF(tzObject.name);
    this.name = tzObject.name;

    this.getOffsetInMinutes = this.getOffsetInMinutes.bind(this);
    this.getHumanName = this.getHumanName.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isUnknown = this.isUnknown.bind(this);
  }

  /**
   *  Returns the offset in minutes for the given date.
   * 
   *  RETURNS: number
   */
  getOffsetInMinutes(dateOrUnixTs = new Date()) {
    let date = null;
    if (dateOrUnixTs instanceof Date)
      date = dateOrUnixTs;
    else
      date = unixToDate(dateOrUnixTs);

    const [
      year,
      month,
      day,
      hour,
      minute,
      second
    ] = partsOffset(this._dtf, date);

    // work around https://bugs.chromium.org/p/chromium/issues/detail?id=1025564&can=2&q=%2224%3A00%22%20datetimeformat
    const adjustedHour = hour === 24 ? 0 : hour;

    const utcDate = new Date(Date.UTC(
      year,
      month - 1,
      day,
      adjustedHour,
      minute,
      second,
      0
    ));

    // for legacy reasons, years between 0 and 99 are interpreted as 19XX; revert that
    if (year < 100 && year >= 0)
      utcDate.setUTCFullYear(utcDate.getUTCFullYear() - 1900);

    // Make sure we correctly handle miliseconds before 1970
    let dateTs = date.getTime();
    const utcTs = utcDate.getTime();
    const over = dateTs % 1000;
    dateTs -= over >= 0 ? over : 1000 + over;

    return (utcTs - dateTs) / (60 * 1000);
  }

  /**
   * Returns the time zone's name in a end-user friendly format: "(UTC+/-hh:mm) main_city, alternative_tz_name"
   * Example: "(UTC+02:00) Ljubljana, Central European Time"
   *
   * RETURNS: string
   */
  getHumanName(utcDateOrUnixTs = new Date()) {
    const {
      mainCities,
      alternativeName,
    } = this._rawTz;

    const offset = this.getOffsetInMinutes(utcDateOrUnixTs)
    const absOffset = Math.abs(offset);
    const offsetSign = offset >= 0 ? '+' : '-';

    const leftOverMinutes = absOffset % 60;
    const fullHours = (absOffset - leftOverMinutes) / 60;

    const offsetStr = sprintf('(UTC%s%02d:%02d)', offsetSign, fullHours, leftOverMinutes);

    return `${offsetStr} ${mainCities[0]}, ${alternativeName}`;
  }

  /**
   * Returns true if this time zone is a valid IANA time zone; false otherwise.
   *
   * RETURNS: boolean
   */
  isValid() {
    if (this.isUnknown())
      return false;

    try {
      this._dtf.format();
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Returns true if this timezone's name is NOT the value of TZ_NAME__UNKNOWN and is NOT empty; False otherwise.
   *
   * RETURNS: bool
   */
    isUnknown() {
      return !this.name || this.name === TZ_NAME__UNKNOWN;
    }

  /**
   * Returns an array of all known IANA time zones as an array of instances of this class.
   *
   * RETURNS: IanaTimeZone[]
   */
  static getAllZones() {
    return rawTimeZones.map(rawTz => new IanaTimeZone(rawTz));
  }

  /*
   * Creates a new instance of this class from a time zone name.
   *
   * RETURNS: IanaTimeZone
   */
  static fromName(name) {
    return new IanaTimeZone(rawTzLookUpObj[name]);
  }

  /*
   * Returns true if the given time zone name identifies a valid IANA time zone; false otherwise.
   *
   * RETURNS: boolean
   */
  static isValidZone(name) {
    const tz = IanaTimeZone.fromName(name);
    return tz.isValid();
  }
}

export default IanaTimeZone;
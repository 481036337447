/**
 * Class that encapsulates and provides information about a warning's type.
 */
 class WarningDefinition {
  constructor(type, category, label) {
    this._type = type;
    this._category = category;
    this._label = label;

    this.getType = this.getType.bind(this);
    this.getCategory = this.getCategory.bind(this);
    this.getLabel = this.getLabel.bind(this);
  }

  /**
   * Returns the type of warning as defined in {@link WARNING_TYPE}.
   * @returns {number}
   */
  getType() {
    return this._type
  }

  /**
   * Returns the category of the warning, ie. one of the values defined in {@link WARNING_CATEGORY}.
   * @returns {number}
   */
  getCategory() {
    return this._category;
  }

  /**
   * Returns a short human readable string that identifies this warning type.
   * @returns {string}
   */
  getLabel() {
    return this._label;
  }
};

export default WarningDefinition;